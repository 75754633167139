<template>
  <v-row justify="center" align="center" class="fill-height">
    <v-col lg="4" md="6" sm="8" cols="11">
      <v-card class="elevation-0">
        <p class="text-h2 text-center">
          {{ $t("authentication.resetPassword.title") }}
        </p>
        <p
          v-if="confirm"
          class="text-body-1 text-center mt-4 mb-8 vitrueGrey--text"
        >
          {{ $t("authentication.resetPassword.tempCode") }}
        </p>
        <v-row justify="center">
          <v-col cols="10" v-if="!confirm">
            <v-form ref="emailForm" v-model="validEmail" @submit.prevent>
              <v-text-field
                v-model="email"
                :label="$t('textFields.email')"
                filled
                :rules="[rules.emailRequired, rules.emailValid]"
                prepend-inner-icon="mdi-email"
              ></v-text-field>
            </v-form>
            <alert-message
              v-if="errorMessage"
              :message="errorMessage"
              type="error"
            ></alert-message>
            <v-btn
              color="primary"
              width="100%"
              @click="sendCode"
              class="mt-4 text-none"
              :loading="sendingCode"
            >
              {{ $t("buttons.continue") }}
            </v-btn>
          </v-col>
          <v-col cols="12" v-else>
            <confirm-code passwordReset :email="email" :password="password" />
          </v-col>
          <v-col cols="10" class="pt-0">
            <v-hover v-slot="{ hover }">
              <v-btn
                text
                color="vitrueGrey"
                class="text-none text-body-1"
                width="100%"
                @click="$router.push('/')"
                small
              >
                <v-icon> mdi-chevron-left </v-icon>
                <span :class="hover ? 'text-decoration-underline' : ''">{{
                  $t("authentication.resetPassword.backToLogin")
                }}</span>
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { Auth } from "aws-amplify";
import AlertMessage from "../account/AlertMessage";
import ConfirmCode from "../authentication/ConfirmCode";
import Rules from "@/utils/stringValidation";

import {
  isExistingUserInAdminCreateState,
  sendNewTempPasswordEmailToUser
} from "@/customApi.js";

export default {
  name: "ResetPassword",
  components: {
    AlertMessage,
    ConfirmCode
  },
  data() {
    return {
      code: "",
      email: "",
      newPassword: "",
      validEmail: false,
      password: "",
      confirm: false,
      errorMessage: "",
      sendingCode: false,
      rules: Rules,
      isAdminCreatedUserInResetState: false
    };
  },
  methods: {
    async sendCode() {
      this.errorMessage = "";
      if (!this.confirm) {
        this.$refs.emailForm.validate();
        if (!this.validEmail) {
          return;
        }
      }
      try {
        this.sendingCode = true;

        var userEmailDto = { emailAddress: { value: this.email } };
        this.isAdminCreatedUserInResetState =
          await isExistingUserInAdminCreateState(userEmailDto);

        if (this.isAdminCreatedUserInResetState) {
          await sendNewTempPasswordEmailToUser(userEmailDto);
          this.$router.push({
            name: "InvitationSignUp",
            params: { signInEmail: this.email }
          });
          return;
        } else {
          await Auth.forgotPassword(this.email);
        }

        this.confirm = true;
      } catch (error) {
        this.confirm = false;
        if (error.message === "UserNotFound") {
          this.errorMessage = this.$t("authentication.userNotFoundError");
        } else {
          this.errorMessage = error.message;
        }
      } finally {
        this.sendingCode = false;
      }
    }
  }
};
</script>
