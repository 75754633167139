var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "fill-height",
      attrs: { justify: "center", align: "center" }
    },
    [
      _c(
        "v-col",
        { attrs: { lg: "4", md: "6", sm: "8", cols: "11" } },
        [
          _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c("p", { staticClass: "text-h2 text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("authentication.resetPassword.title")) +
                    " "
                )
              ]),
              _vm.confirm
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-body-1 text-center mt-4 mb-8 vitrueGrey--text"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("authentication.resetPassword.tempCode")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  !_vm.confirm
                    ? _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "emailForm",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              },
                              model: {
                                value: _vm.validEmail,
                                callback: function($$v) {
                                  _vm.validEmail = $$v
                                },
                                expression: "validEmail"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("textFields.email"),
                                  filled: "",
                                  rules: [
                                    _vm.rules.emailRequired,
                                    _vm.rules.emailValid
                                  ],
                                  "prepend-inner-icon": "mdi-email"
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.errorMessage
                            ? _c("alert-message", {
                                attrs: {
                                  message: _vm.errorMessage,
                                  type: "error"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4 text-none",
                              attrs: {
                                color: "primary",
                                width: "100%",
                                loading: _vm.sendingCode
                              },
                              on: { click: _vm.sendCode }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("buttons.continue")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("confirm-code", {
                            attrs: {
                              passwordReset: "",
                              email: _vm.email,
                              password: _vm.password
                            }
                          })
                        ],
                        1
                      ),
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { cols: "10" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-none text-body-1",
                                    attrs: {
                                      text: "",
                                      color: "vitrueGrey",
                                      width: "100%",
                                      small: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push("/")
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(" mdi-chevron-left ")
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        class: hover
                                          ? "text-decoration-underline"
                                          : ""
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "authentication.resetPassword.backToLogin"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }